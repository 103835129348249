

export const currentOrderAttributes = {
    cs: '5% 14% 10% 8% 9% 9% 10% 10% 8% 10% 7%',
    headers: [
        { title: 'name' },
        { title: 'market' },
        { title: 'trade-type' },
        { title: 'amount' },
        { title: 'traded-amount', sort: 'tradedAmount' },
        { title: 'unitPrice', sort: 'priceUnit' },
        { title: 'price', sort: 'price' },
        { title: 'transaction-type' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const stakingReportAttributes = {
    cs: '5% 20% 15% 15% 30% 10% 5%',
    headers: [
        { title: "username" },
        { title: "coin" },
        { title: "amount" },
        { title: "see-plan" },
        { title: "date" }
    ]
}

export const otcOrdersAttributes = {
    cs: '4% 15% 10% 10% 12% 12% 12% 10% 10% 5%',
    headers: [
        { title: 'name' },
        { title: 'coin' },
        { title: 'amount', sort: 'amount' },
        { title: 'wage', sort: 'wage' },
        { title: 'unitPrice', sort: 'priceUnit' },
        { title: 'price', sort: 'price' },
        { title: 'transaction-type' },
        { title: 'traded-date', sort: 'tradedAt' }
    ]
}

export const tradeAttributes = {
    cs: '5% 14% 14% 10% 8% 10% 10% 8% 8% 8% 5%',
    headers: [
        { title: 'maker' },
        { title: 'taker' },
        { title: 'market' },
        { title: 'trade-type' },
        { title: 'amount', sort: 'amount' },
        { title: 'unitPrice', sort: 'priceUnit' },
        { title: 'price', sort: 'price' },
        { title: 'transaction-type' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const userAttributes = {
    cs: '5% 12% 8% 8% 10% 20% 10% 10% 12% 5%',
    headers: [
        { title: 'fullname' },
        { title: 'idNo' },
        { title: 'mobile' },
        { title: 'balance', sort: 'balance' },
        { title: 'email' },
        { title: 'user-status' },
        { title: 'documents-status' },
        { title: 'replyBy' },
    ]
}


export const cartableAttributes = {
    cs: '5% 10% 20% 20% 10% 10% 10% 10% 5%',
    headers: [
        { title: 'currency' },
        { title: 'askBy' },
        { title: 'replyBy' },
        { title: 'volume' },
        { title: 'action' },
        { title: 'status' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const bankAccountAttributes = {
    cs: '5% 15% 10% 15% 20% 10% 20% 5%',
    headers: [
        { title: 'fullname' },
        { title: 'user-status' },
        { title: 'card-number' },
        { title: 'sheba-number' },
        { title: 'bank-label' },
        { title: 'replyBy' },
    ]
}

export const fileAttributes = {
    cs: '5% 40% 18% 18% 12% 8%',
    headers: [
        { title: 'file-name' },
        { title: 'file-type' },
        { title: 'file-status' },
        { title: 'date', sort: 'createdAt' },
    ]
}

export const coinTransactionAttributes = {
    cs: '5% 15% 15% 15% 15% 15% 15% 5%',
    headers: [
        { title: 'name' },
        { title: 'coin' },
        { title: 'amount', sort: 'amount' },
        { title: 'transaction-type' },
        { title: 'transaction-status' },
        { title: 'date', sort: 'createdAt' },
    ]
}


export const bankTransactionAttributes = {
    cs: '5% 20% 17% 17% 16% 20% 5%',
    headers: [
        { title: 'name' },
        { title: 'amount', sort: 'amount' },
        { title: 'transaction-type' },
        { title: 'transaction-status' },
        { title: 'date', sort: 'createdAt' },
    ]
}

export const transferAttributes = {
    cs: '5% 20% 15% 15% 15% 15% 15%',
    headers: [
        { title: 'currency' },
        { title: 'sender' },
        { title: 'receiver' },
        { title: 'amount', sort: 'amount' },
        { title: 'wage', sort: 'wage' },
        { title: 'date', sort: 'createdAt' },
    ]
}

export const bankWebhookAttributes = {
    cs: '5% 15% 15% 10% 10% 10% 10% 10% 10% 5%',
    headers: [
        { title: 'amount' },
        { title: 'wage' },
        { title: 'sourceIban' },
        { title: 'destinationIban' },
        { title: 'payId' },
        { title: 'thirdParty' },
        { title: 'trackingCode' },
        { title: 'payDate' },
    ]
}

export const bankDepositAccountsAttributes = {
    cs: '5% 50% 20% 20% 5%',
    headers: [
        { title: 'user' },
        { title: 'ip' },
        { title: 'thirdParty' },
    ]
}

export const wagesAttributes = {
    cs: '5% 25% 25% 20% 20% 5%',
    headers: [
        { title: 'coin' },
        { title: 'count' },
        { title: 'volume' },
        { title: 'wage' }
    ]
}

export const traceAttributes = {
    cs: '5% 15% 10% 10% 10% 10% 10% 10% 15% 5%',
    headers: [
        { title: 'name' },
        { title: 'coin' },
        { title: 'volume', sort: 'volume' },
        { title: 'wage', sort: 'wage' },
        { title: 'cause' },
        { title: 'action' },
        { title: 'trace-type' },
        { title: 'date', sort: 'createdAt' },
    ]
}


export const affiliateListAttribute = {
    cs: '5% 15% 12% 12% 11% 25% 12% 8%',
    headers: [
        { title: 'name' },
        { title: 'affiliate-code' },
        { title: 'use-count', sort: 'used' },
        { title: 'max-use', sort: 'maxUse' },
        { title: 'share' },
        { title: 'date', sort: 'createdAt' },
    ]
}


export const affiliateTraceAttribute = {
    cs: '5% 20% 35% 35% 5%',
    headers: [
        { title: 'coin' },
        { title: 'actor-share' },
        { title: 'object-share' },
    ]
}

export const addressAttributes = {
    cs: '5% 15% 15% 50% 15%',
    headers: [
        { title: 'name' },
        { title: 'coin' },
        { title: 'address' },
        { title: 'date', sort: 'createdAt' },
    ]
}

export const availableBanksAttributes = {
    cs: '5% 25% 25% 25% 20%',
    headers: [
        { title: 'symbol' },
        { title: 'en-name' },
        { title: 'fa-name' },
        { title: 'ab-status' },
    ]
}

export const thirdBalanceKucoinAttributes = {
    cs: '5% 20% 20% 20% 20% 15%',
    headers: [
        { title: 'coin' },
        { title: 'type' },
        { title: 'balance' },
        { title: 'available' },
        { title: 'holds' },
    ]
}

export const thirdBalanceBinanceAttributes = {
    cs: '5% 15% 15% 15% 15% 12% 12% 12%',
    headers: [
        { title: 'asset' },
        { title: 'btcValuation' },
        { title: 'free' },
        { title: 'freeze' },
        { title: 'ipoable' },
        { title: 'locked' },
        { title: 'withdrawing' },
    ]
}

export const thirdBalancesNobitexAttributes = {
    cs: '5% 30% 25% 20% 20%',
    headers: [
        { title: 'coin' },
        { title: 'id' },
        { title: 'balance' },
        { title: 'blocked' }
    ]
}


export const pairAttributes = {
    cs: '5% 30% 30% 30% 5%',
    headers: [
        { title: 'coin' },
        { title: 'en-name' },
        { title: 'fa-name' }
    ]
}


export const roleAttributes = {
    cs: '5% 10% 70% 10% 5%',
    headers: [
        { title: 'name', sort: 'name' },
        { title: 'permissions' },
        { title: 'date', sort: 'createdAt' }
    ]
}


export const tokenAttributes = {
    cs: '5% 15% 15% 15% 15% 15% 15% 5%',
    headers: [
        { title: 'token' },
        { title: 'blockchain', sort: 'name' },
        { title: 'network' },
        { title: 'decimal', sort: 'decimal' },
        { title: 'is-active' },
        { title: 'is-available' }
    ]
}

export const availableCoinsWDListAttributes = {
    cs: "5% 20% 20% 20% 15% 15% 5%",
    headers: [
        { title: "network" },
        { title: "min" },
        { title: "max" },
        { title: "fee-factor" },
        { title: "fee-max" }
    ]
}

export const fullnodeStatAttributes = {
    cs: '10% 25% 35% 30%',
    headers: [
        { title: 'coin' },
        { title: 'amount' },
        { title: 'count' },
    ]
}

export const fullnodeListAttributes = {
    cs: '5% 15% 15% 10% 10% 10% 10% 10% 10% 5%',
    headers: [
        { title: 'from-address' },
        { title: 'to-address' },
        { title: 'coin' },
        { title: 'network' },
        { title: 'amount' },
        { title: 'txHash' },
        { title: 'status' },
        { title: 'date' },
    ]
}


export const coinCategoryAttributes = {
    cs: '15% 15% 65% 5%',
    headers: [
        { title: 'name' },
        { title: 'icon' },
        { title: 'symbols' }
    ]
}

export const thirdOrderHistoryAttributes = {
    cs: '',
    headers: []
}

export const nobitexOrderHistoryAttributes = {
    cs: '5% 10% 10% 10% 10% 10% 10% 10% 10% 15%',
    headers: [
        { title: 'id' },
        { title: 'market' },
        { title: 'price' },
        { title: 'amount' },
        { title: 'total' },
        { title: 'fee' },
        { title: 'type' },
        { title: 'orderId' },
        { title: 'date' },
    ]
}

export const thirdWithdrawHistoryAttributes = {
    cs: '',
    headers: []
}

export const nobitexWithdrawHistoryAttributes = {
    cs: '5% 10% 10% 10% 10% 10% 10% 10% 10% 15%',
    headers: [
        { title: 'id' },
        { title: 'currency' },
        { title: 'amount' },
        { title: 'address' },
        { title: 'wallet_id' },
        { title: 'blockchain_url' },
        { title: 'network' },
        { title: 'status' },
        { title: 'date' },
    ]
}
