import { useMemo, useState } from 'react'
import {
  useAddUserStake,
  useGetStakingPlans,
} from '../../../../../core/services/react-query/staking'
import { formatNumber, stringToNumber } from '../../../../../core/utils/common'
import Text from '../../../../../core/utils/Text'
import { Background, Padding } from '../../../../../styles/CommonStyles'
import ActionButton from '../../../../common/buttons/ActionButton'
import CoinRow from '../../../../common/common/CoinRow'
import Select from '../../../../common/dropdown/Select'
import Input from '../../../../common/input/Input'

const AddStaking = ({ user, refetch }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [amount, setAmount] = useState('')
  const { data: plans } = useGetStakingPlans()
  const { mutate: addStakingPlan, isLoading } = useAddUserStake(refetch)

  const onSubmit = () => {
    if (!amount || !selectedPlan || isLoading) return
    const payload = {
      amount: stringToNumber(amount),
      planId: selectedPlan.id,
      userId: user?._id,
    }
    addStakingPlan(payload)
  }

  const userBalance = useMemo(() => {
    let balance = 0
    if (!!user && !!selectedPlan) {
      let wallet
      if (selectedPlan.currency === 'irt') {
        return user.balance
      } else {
        console.log({ user })
        wallet = user.coins.find((c) => c.coin === selectedPlan.currency)
      }
      if (!!wallet) {
        balance = wallet.amount
      }
    }
    return balance
  }, [user, selectedPlan])

  return (
    <div className={'flex justify-center dark:text-gray-300 py-2'}>
      <div className={'flex w-[320px] flex-col gap-2'}>
        <Text tid={'add-staking-note'} />
        <div className={'w-full h-[2px] bg-gray-500 bg-opacity-20'} />
        <div className={'flex flex-col mt-3'}>
          <Text tid={'see-plan'} className={'text-sm mx-2'} />

          <Padding padding={'4px'}>
            <Select
              value={selectedPlan?.name}
              onValueChange={(idx) => setSelectedPlan(plans[idx])}
              options={plans?.map((x) => x.name) || []}
              size={'small'}
              width={'100%'}
              height={'44px'}
              placeHolder={'select-plan'}
            />
          </Padding>
        </div>
        {!!selectedPlan ? (
          <Padding padding={'4px'}>
            <div className={'flex flex-col'}>
              <Text tid={'coin'} className={'text-sm mx-2'} />
              <div
                className={`rounded w-full py-2 px-3 border-[1px] 
                border-gray-500 border-opacity-40 dark:bg-primaryBg flex justify-between items-center`}
              >
                <CoinRow
                  coin={{
                    id: selectedPlan.currency,
                    name: selectedPlan.currency,
                    fa: selectedPlan.currency,
                  }}
                />
                <div className={'text-xs dark:text-gray-400 text-gray-600 flex items-center gap-1'}>
                  <Text tid={'balance-col'} />
                  <span>{formatNumber(userBalance, { type: selectedPlan?.currency })}</span>
                </div>
              </div>
            </div>
          </Padding>
        ) : null}

        <Background bg={'mainBg'} className={'mt-1'}>
          <Input
            value={amount}
            onInputChange={setAmount}
            label={'amount'}
            size={'small'}
            height={'44px'}
            number
          />
        </Background>

        <ActionButton active={!!selectedPlan && !!amount} onClick={onSubmit}>
          <Text tid={'add-plan'} />
        </ActionButton>
      </div>
    </div>
  )
}

export default AddStaking
